::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  /* border-radius: 5px; */
  background: #e7e7e7;
}

::-webkit-scrollbar-thumb {
  background: #5f5f5f;
  /* border-radius: 5px; */
  /* border: 2px solid #f6f7ed; */
}

::-webkit-scrollbar-thumb:hover {
  background: #8d8d8d;
}